<template>
  <div>
    <Row :gutter="8">
      <i-col span="4" class="station_box">
        <div class="m-b-10">
          <Button type="primary" size="small" icon="md-arrow-back" @click="onBackLlist">返回列表设置</Button>
        </div>
        <Row :gutter="8" class="m-b-10">
          <i-col span="24">
            <Select v-model="assetId" clearable size="small" placeholder="选择资产" @on-change="handleChangeAsset">
              <Option v-for="item in assetArray" :key="'asset_' + item.assetId" :value="item.assetId">{{ item.assetName }}
              </Option>
            </Select>
          </i-col>
        </Row>
        <ul class="devcie_list">
          <li v-for="item in showStationArray" :class="curStationId === item.stationId ? 'device_li_actived' : 'device_li_default'"
            :key="'device_' + item.stationId" @click="handleChoiceItem(item)">{{ item.assetName + ' - ' + item.stationName
            }}
          </li>
        </ul>
      </i-col>
      <i-col span="20">
        <SvgStationScreenSet ref="svgStation" :stationId="curStationId" :floors="floors"
          @load-device-info="getPublishingData" @on-select="showInstallInfo" @on-batch-set="batchSetScreen">
        </SvgStationScreenSet>
      </i-col>
    </Row>
    <Modal v-model="setModal" width="810" footer-hide>
      <p slot="header" class="text-center">{{ isBatchSet ? '设置素材' : curDeviceInfo.resourceCode }}</p>
      <Tabs v-model="curTab" :key="tabsKey">
        <TabPane v-if="showScreenDetail" label="素材详情" name="tab_1">
          <img-light-box ref="imgLightBox" :showModal="setModal" :sortIndex="0"></img-light-box>
        </TabPane>
        <TabPane v-if="showSetScreen" label="素材设置" name="tab_2">
          <Card>
            <div>
              <h4 class="workplatform-title m-t-10 m-b-20">作业日期</h4>
              <DatePicker placeholder="选择作业日期" size="small" v-model="actionDate" type="date" style="width:100%"
                :editable="false" :clearable="false"></DatePicker>
            </div>
            <set-screen :agreeFileArray="agreeFileArray" :selectedResourceIds="selecteDeviceIds" :curTaskId="curTaskId"
              :selectedProductId="selectedProductId" :selectedTaskitemIds="selectedTaskitemIds"
              :setOperatingType="setOperatingType" :btnSetLoading="btnSetLoading"
              :selectedStMediaAttr="selectedStMediaAttr" @parentMethod="handelSubmit"></set-screen>
          </Card>
        </TabPane>
      </Tabs>
    </Modal>
  </div>
</template>

<script>
import { agreeFile } from '@/api/order/orderfile'
import { screenSetMixins } from '../mixins/screenSet'
import { ParseDate } from '@/utils/dateFormat'
import { updateTaskitemFile } from '@/api/msp/demandV3'
import SvgStationScreenSet from '@/components/svg/SvgStationScreenSet'
import ImgLightBox from '@/components/common/ImgVideoLightBox'
import SetScreen from '@/components/task/SetScreen'
export default {
  mixins: [screenSetMixins],
  components: { SvgStationScreenSet, ImgLightBox, SetScreen },
  data () {
    return {
      assetId: null,
      assetArray: [],
      stationArray: [],
      showStationArray: [],
      curStation: {},
      curStationId: -1,
      floors: [],
      deviceInfoArray: [],
      setModal: false,
      curDeviceInfo: {},
      curTab: 'tab_1',
      isBatchSet: false, // 是否为批量设置
      // showScreenDetail: false, // 显示素材详情
      showSetScreen: false, // 显示更改设置
      tabsKey: 1,
      // 素材设置需要的参数
      query: {
        approvalDate: '',
        orderId: null,
        pageNumber: 1,
        pageSize: 200,
        types: JSON.stringify([1, 3, 4]), // 画面、视频、音频
        advertiserId: null,
        brandId: null
      },
      actionDate: '',
      selecteDeviceIds: [],
      agreeFileArray: [],
      selectedProductId: -1,
      selectedTaskitemIds: [],
      setOperatingType: 1,
      btnSetLoading: false,
      selectedStMediaAttr: {}
    }
  },
  computed: {
    curTaskId () {
      return this.$store.state.installSetting.currentTaskId
    },
    orderInfo () {
      return this.$store.state.installSetting.orderInfo
    },
    showScreenDetail () {
      let flag = false
      if (!this.isBatchSet) {
        if ((this.curDeviceInfo.taskitemFileList && this.curDeviceInfo.taskitemFileList.length) || (this.curDeviceInfo.waitSetTaskitemFileList && this.curDeviceInfo.waitSetTaskitemFileList.length)) {
          flag = true
        }
      }
      return flag
    }
  },
  created () {
    this.getStationData()
  },
  methods: {
    onBackLlist () {
      this.$store.commit('set_bottom_component', 'DemandDetail')
    },
    /**
        * 获取审批通过的文件
        */
    getAgreeFileData () {
      this.query.orderId = this.orderInfo.id

      agreeFile(this.query).then(res => {
        if (res && !res.errcode) {
          this.agreeFileArray = res.list
        } else {
          this.agreeFileArray = []
        }
      })
    },

    showInstallInfo (deviceInfo) {
      this.tabsKey++
      this.isBatchSet = false
      this.curDeviceInfo = deviceInfo
      this.curTab = 'tab_1'
      this.setOperatingType = 2
      this.$store.commit('set_operating_type', this.setOperatingType)
      // this.showScreenDetail = false
      // if ((this.curDeviceInfo.taskitemFileList && this.curDeviceInfo.taskitemFileList.length) || (this.curDeviceInfo.waitSetTaskitemFileList && this.curDeviceInfo.waitSetTaskitemFileList.length)) {
      //   this.showScreenDetail = true
      // }

      this.showSetScreen = this.curDeviceInfo.publishWithNotSetDeviceFlag === 2 && this.curDeviceInfo.taskStatus === -2
      this.selecteDeviceIds = [deviceInfo.deviceId]
      this.selectedTaskitemIds = [deviceInfo.waitSetTaskitemId]
      const postData = {
        startIndex: 0,
        imgList: this.curDeviceInfo.publishWithNotSetDeviceFlag === 1 ? this.curDeviceInfo.taskitemFileList : this.curDeviceInfo.waitSetTaskitemFileList
      }
      this.$nextTick(() => {
        this.$refs.imgLightBox.init(postData)
      })
      this.getAgreeFileData()
      this.setModal = true
    },
    batchSetScreen (selecteDeviceInfoList) {
      this.tabsKey++
      this.isBatchSet = true
      this.showSetScreen = true
      this.curTab = 'tab_2'
      this.setOperatingType = 2
      // 选中的设备任务信息
      this.selecteDeviceIds = selecteDeviceInfoList.map(x => x.deviceId)
      this.$store.commit('set_operating_type', this.setOperatingType)
      this.selectedTaskitemIds = selecteDeviceInfoList.map(x => x.waitSetTaskitemId)
      this.getAgreeFileData()
      this.setModal = true
    },
    handelSubmit (data) {
      if (!this.actionDate) {
        this.$Notice.error({ desc: '请选择作业日期' })
        return false
      }

      if (data && data instanceof Object) {
        this.btnSetLoading = true
        data.taskId = this.curTaskId
        data.actionDate = ParseDate(this.actionDate)

        updateTaskitemFile(data).then(res => {
          this.btnSetLoading = false
          if (res && res.errcode === 0) {
            this.handleSubmitSuccess()
          }
        }).finally(() => {
          this.setModal = false
          this.tabsKey++
        })
      } else {
        this.$Notice.error({ desc: '非法请求' })
      }
    },
    /**
     * 处理成功提交后事件
     */
    handleSubmitSuccess () {
      // 更新store中数据
      this.selecteDeviceIds = []
      this.selectedTaskitemIds = []
      // 调用列表数据获取函数
      this.stationSvgInit()

      this.$Notice.success({ desc: '操作成功' })
    }
  }
}
</script>

<style lang="less">
.station_box {
  border-right: 1px dotted #777777;
  min-height: 100vh;
}
</style>
